
import { 
    getData
} from '@/store/config/method'

const requestInit = {
    page: 1,
    show: 10,
    sortBy: null,
    sorting: null,
    search: null,
    edu_level: 'Semua Unit',
    edu_level_id: null,
    edu_level_slot: 'Pilih Tahun Ajaran',
    edu_level_slot_id: null,
    status_label: 'Semua Status',
    status: null
}

const itemInit = {
    id: null,
    edu_level_id: null,
    edu_level: null,
    edu_level_class_id: null,
    edu_level_class: null,
    code: null,
    quota: 30,
}

const teacherInit = {
    id: null,
    edu_level_slot_id: null,
    edu_level_slot: null,
    teacher_name: null,
    teacher_id: null,
    code: null,
    edu_level: null,
    edu_level_class: null,
    short_name: null,
}

const initialState = () => ({
    message: null,
    status: 0,
    meta: {},
    data: [],
    request: {...requestInit},
    item: {...itemInit},
    teacherItem: {...teacherInit},
    list: []
})
const state = initialState
 
const getters = {
    getMessage: state => state.message,
    getStatus: state => state.status,
    getData: state => state.data || [],
    getMeta: state => state.meta || {},
    getRequest: state => state.request,
    getItem: state => state.item,
    getList: state => state.list,
    getTeacherItem: state => state.teacherItem
}

const actions = {
    resetRequest({commit}){
        commit('SET_REQUEST',{...requestInit})
    },
    resetItem({commit}){
        commit('SET_ITEM',{...itemInit})
    },
    setItem({commit}, value){
        commit('SET_ITEM',{...value})
    },
    resetTeacherItem({commit}){
        commit('SET_TEACHER_ITEM',{...teacherInit})
    },
    setTeacherItem({commit}, value){
        commit('SET_TEACHER_ITEM',{...value})
    },
    async fetchDataByUser({
        commit, state
    }){
        try {
            const response = await getData('api','/management/teacher/class', {params: state.request});
            commit('SET_RESPONSE', response.data);
            commit('SET_DATA', response.data.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    
    resetState({commit}){
        commit('resetState')
    }
}

const mutations = {
    SET_FAILED(state) {
        state.message = 'Jaringan Bermasalah'
        state.status = 0
    },
    SET_FAILED_MESSAGE(state, error) {
        if(error.status == 400){
            state.message = error.data.message
            state.status = error.data.status
        }else{
            state.message = 'Jaringan Bermasalah'
            state.status = 0
        }
    },
    SET_RESPONSE(state, response) {
        state.message = response.message
        state.status = response.status
    },
    SET_DATA(state, response) {
        state.data = response.data
        state.meta = {
            current_page: response.current_page,
            from: response.from,
            last_page: response.last_page,
            per_page: response.per_page,
            to: response.to,
            total: response.total
        }
    },
    SET_LIST(state, value) {
        state.list = value
    },
    SET_REQUEST(state, value){
        state.request = value
    },
    SET_ITEM(state, value){
        state.item = value
    },
    SET_TEACHER_ITEM(state, value){
        state.teacherItem = value
    },
    SET_STUDENTS(state, value){
        state.students = value
    },
    resetState (state) {
        const initial = initialState()
        Object.keys(initial).forEach(key => { state[key] = initial[key] })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}