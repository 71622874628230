<template>
    <div id="sidebar">
        <div class="r-sidebar-header">
            <router-link class="r-sidebar-item" :to="{name: 'Dashboard'}">
                <div class="r-sidebar-image">
                    <div class="r-wd-50-max r-flex">
                        <img class="r-isExpand" :src="require(`@/assets/image/avatar/Avatar-${avatar}.svg`)" alt="temp">
                    </div>
                </div>
                <div class="r-ml-10 r-inline-grid">
                    <p class="r-ellipsis"><strong>Hi, {{user.name}} </strong></p>
                    <p>{{user.role_label}}</p>
                </div>
            </router-link>
        </div>
        <SidebarBody
            :user="user"
        >
           <template #notification>
               <div class="r-center-flex r-isExpand">
                   <span class="r-ellipsis r-sidebar-label r-mr-auto">Notifikasi</span>
                   <span class="r-notification-count" v-if="count > 0">{{count}}</span>
               </div>
           </template>
        </SidebarBody>
        <div class="r-sidebar-logout">
            <router-link class="r-sidebar-item" :to="{name: 'Logout'}">
                <img 
                    class="r-mr-10"
                    :src="require('@/assets/icons/error/logout.svg')" alt="logout">
                <span>Logout</span>
            </router-link>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

import SidebarBody from './SidebarBody'

export default {
    components: {
        SidebarBody
    },
    computed: {
        ...mapGetters({
            user: 'auth/getUser',
            count: 'notification/getCount'
        }),
    },
    data: () => ({
        avatar: 1,
    }),
    mounted(){
        this.initData()
    },
    methods: {
        ...mapActions({
            getCountData: 'notification/getCountData'
        }),
        randomAvatar(){
            return Math.floor(Math.random() * 98) + 1;
        },
        async initData(){
            this.avatar = this.randomAvatar()
            await this.getCountData()
        }
    }
}
</script>