<template>
    <div class="r-sidebar-body">
        <div class="r-sidebar-items" 
            v-for="(s,i) in activeRoute" :key="`sidebar-${i}`">
            <template v-for="(r,j) in s.routes" :key="`${i}-sRoutes-${j}`" >                    
                <router-link class="r-sidebar-item" :to="r.route" 
                    @mouseenter="hoverRoute = `${i}-sRoutes-${j}`"
                    @mouseleave="hoverRoute = null"
                    v-if="r.route.name"
                    >
                    <img class="r-mr-10 r-wd-24"
                        :src="require(`@/assets/icons/${isActiveSidebar(r, `${i}-sRoutes-${j}`) ? 'primary' : 'black'}/${r.icon}.svg`)">
                    <!-- <span :class="r.class">{{r.icon}}</span>  -->
                    <slot :name="r.slot">
                        <span class="r-ellipsis r-sidebar-label">{{r.label}}</span>
                    </slot>
                </router-link>
                <a class="r-sidebar-item" :href="r.link" 
                    @mouseenter="hoverRoute = `${i}-sRoutes-${j}`"
                    @mouseleave="hoverRoute = null"
                    v-else
                    >
                    <img class="r-mr-10 r-wd-24"
                        :src="require(`@/assets/icons/${isActiveSidebar(r, `${i}-sRoutes-${j}`) ? 'primary' : 'black'}/${r.icon}.svg`)">
                    <!-- <span :class="r.class">{{r.icon}}</span>  -->
                    <span class="r-ellipsis r-sidebar-label">{{r.label}}</span>
                </a>
            </template>
        </div>
    </div>
</template>
<script>

export default {
    name: 'SidebarBody',
    props: {
        user: {
            default: {}
        }
    },
    computed: {
        activeRoute(){
            const role = this.user.role
            return this.sidebar.map(x => {
                return {
                routes: x.routes.filter(y => 
                    (y.roles.some(z => z == role) || y.roles.length == 0))
                }
            }).filter(x => x.routes.length > 0)
        },
    },
    data: () => ({
        hoverRoute: null,
        sidebar: [
            {
                routes: [
                    {
                        route: {name: 'Dashboard'},
                        label: 'Beranda',
                        icon: 'category',
                        class: 'material-icons',
                        meta: 'Dashboard',
                        roles: []
                    },
                    {
                        route: {name: 'Notification'},
                        label: '',
                        slot: 'notification',
                        icon: 'notification',
                        class: 'material-icons',
                        meta: 'Notification',
                        roles: ['admin', 'staff', 'superadmin']
                    },
                ],
            },
            {
                routes: [
                    {
                        route: {name: 'UnitData'},
                        label: 'Data Unit',
                        icon: 'home',
                        class: 'material-icons',
                        meta: 'UnitData',
                        roles: ['admin']
                    },
                    {
                        route: {name: 'SchoolYear'},
                        label: 'Daftar Tahun Ajaran',
                        icon: 'time_square',
                        class: 'material-icons',
                        meta: 'SchoolYear',
                        roles: ['admin']
                    },
                    {
                        route: {name: 'Teacher'},
                        label: 'Data Guru',
                        icon: 'profile',
                        class: 'material-icons',
                        meta: 'Teacher',
                        roles: ['admin', 'staff']
                    },
                    {
                        route: {name: 'ClassRoom'},
                        label: 'Data Kelas',
                        icon: 'star',
                        class: 'material-icons',
                        meta: 'ClassRoom',
                        roles: ['admin', 'staff']
                    },
                    {
                        route: {name: null},
                        link: 'https://classroom.google.com',
                        label: 'Google Class Room',
                        icon: 'google_classroom_icon',
                        class: 'material-icons',
                        meta: 'ClassRoom',
                        roles: ['teacher']
                    },
                    {
                        route: {name: 'ClassRoom'},
                        label: 'Daftar Kelas',
                        icon: 'star',
                        class: 'material-icons',
                        meta: 'ClassRoom',
                        roles: ['teacher']
                    },
                    {
                        route: {name: 'Student'},
                        label: 'Data Siswa',
                        icon: '2_user',
                        class: 'material-icons',
                        meta: 'Student',
                        roles: ['admin', 'staff']
                    },
                ],
            },
            {
                routes: [
                    {
                        route: {name: 'Parent'},
                        label: 'Data Orang Tua',
                        icon: '3_user',
                        class: 'material-icons',
                        meta: 'Parent',
                        roles: ['admin', 'staff']
                    },
                    {
                        route: {name: 'Children'},
                        label: 'Data Induk',
                        icon: '2_user',
                        class: 'material-icons',
                        meta: 'Children',
                        roles: ['admin', 'staff']
                    },

                ],
            },
            {
                routes: [
                    {
                        route: {name: 'EducationSubject', params: {education_subject_type: 'mata-pelajaran'}},
                        label: 'Daftar Mata Pelajaran',
                        icon: 'paper',
                        class: 'material-icons',
                        meta: 'EducationSubject',
                        roles: ['admin']
                    },
                    {
                        route: {name: 'EducationSubject', params: {education_subject_type: 'ekstrakurikuler'}},
                        label: 'Daftar Ekstrakurikuler',
                        icon: 'game',
                        class: 'material-icons',
                        meta: 'EducationSubject',
                        roles: ['admin']
                    },
                    {
                        route: {name: 'EducationSubjectAdvisor'},
                        label: 'Pembimbing Ekstrakurikuler',
                        icon: 'game',
                        class: 'material-icons',
                        meta: 'EducationSubjectAdvisor',
                        roles: ['admin', 'staff']
                    },
                ],
            },
            {
                routes: [
                    {
                        route: {name: 'ClassRoomReport'},
                        label: 'Wali Kelas',
                        icon: 'chart',
                        class: 'material-icons',
                        meta: 'ClassRoomReport',
                        roles: ['teacher']
                    },
                ],
            },
            {
                routes: [
                    {
                        route: {name: 'WebManagement'},
                        label: 'Manajemen Web Sekolah',
                        icon: 'work',
                        class: 'material-icons',
                        meta: 'WebManagement',
                        roles: ['admin']
                    },
                    {
                        route: {name: 'Admission'},
                        label: 'Penerimaan Siswa Baru',
                        icon: 'add_user',
                        class: 'material-icons',
                        meta: 'Admission',
                        roles: ['admin', 'staff']
                    },
                    {
                        route: {name: 'Article', params: {article_type: 'pengumuman'}},
                        label: 'Pengumuman',
                        icon: 'bookmark',
                        class: 'material-icons',
                        meta: 'Article',
                        roles: ['admin', 'staff']
                    },
                    {
                        route: {name: 'BlogCategory'},
                        label: 'Kategori Blog',
                        icon: 'discovery',
                        class: 'material-icons',
                        meta: 'BlogCategory',
                        roles: ['admin', 'staff']
                    },
                    {
                        route: {name: 'Article', params: {article_type: 'blog'}},
                        label: 'Blog',
                        icon: 'edit',
                        class: 'material-icons',
                        meta: 'Article',
                        roles: ['admin', 'staff']
                    },
                    {
                        route: {name: 'Article', params: {article_type: 'prestasi'}},
                        label: 'Prestasi',
                        icon: 'ticket_star',
                        class: 'material-icons',
                        meta: 'Article',
                        roles: ['admin', 'staff']
                    },
                ],
            },
            {
                routes: [
                    {
                        route: {name: 'FileLayout'},
                        label: 'Manajemen Media',
                        icon: 'folder',
                        class: 'material-icons',
                        meta: 'FileLayout',
                        roles: ['admin', 'staff']
                    },
                    {
                        route: {name: 'Suggestion'},
                        label: 'Kritik Saran',
                        icon: 'message',
                        class: 'material-icons',
                        meta: 'Suggestion',
                        roles: ['admin', 'staff']
                    },
                    
                ],
            },
            {
                routes: [
                    {
                        route: {name: 'Operator'},
                        label: 'Daftar Operator',
                        icon: 'shield_done',
                        class: 'material-icons',
                        meta: 'Operator',
                        roles: ['admin']
                    },
                    {
                        route: {name: 'AccountSetting'},
                        label: 'Pengaturan Akun',
                        icon: 'setting',
                        class: 'material-icons',
                        meta: 'AccountSetting',
                        roles: ['admin', 'staff', 'teacher']
                    },
                    {
                        route: {name: 'Faq'},
                        label: 'Faq',
                        icon: 'shield_done',
                        class: 'material-icons',
                        meta: 'Faq',
                        roles: ['admin']
                    },
                ],
            },
        ]
    }),
    methods: {
        isActiveSidebar(r, j){
            const route = r.route
            return this.$route.name == route.name && JSON.stringify(this.$route.params) == (JSON.stringify(route.params) || '{}') || this.hoverRoute == j
        }
    }
}
</script>