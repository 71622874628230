<template>
  <MetaTag />
  <Snackbar />
  <router-view />
</template>
<script>
import MetaTag from '@/components/MetaTag'
import Snackbar from '@/components/helper/Snackbar'
import { mapGetters } from 'vuex'
import LogRocket from 'logrocket';
export default {
  components: {
    MetaTag,
    Snackbar,
  },
  computed: {
    ...mapGetters({
      getisLoggedIn: 'getisLoggedIn',
      user: 'auth/getUser',
      key: 'auth/getSchKey',
    })
  },
  data: () => ({
     logEnable: process.env.VUE_APP_LOGROCKET_ENABLE
  }),
  mounted(){
    this.$store.dispatch('setIsMobile',window.innerWidth)
    this.$nextTick(() => {
        window.addEventListener('resize', this.onResize);
    })
    if(process.env.VUE_APP_LOGROCKET_ENABLE == 1) this.initUserLog()
  },
  unmounted() { 
    window.removeEventListener('resize', this.onResize); 
  },
  methods: {
    initUserLog(){
        if(!this.user.id) return
        LogRocket.init(process.env.VUE_APP_LOGROCKET_ID);
        LogRocket.identify(this.user.id, {
            name: this.user.name
        });
    },
    onResize() {
      this.$store.dispatch('setIsMobile',window.innerWidth)
    },
    async gotoRoute(name){
      try{
        await this.$router.push({name})
      }catch(_){
        await this.$router.push({name: 'NotFound', })
      }
    }
  }
}
</script>