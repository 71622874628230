import { routerFilter, loadAuth } from '@/helpers/routes'

const routes = [
    {
        path: '',
        name: 'Login',
        meta: {
            requiresAuth: 0,
            role: []
        },
        component: loadAuth('Login')
    },
    {
        path: '/verify/:token',
        name: 'Verify',
        meta: {
            requiresAuth: 0,
            role: []
        },
        component: loadAuth('Verify')
    },
    {
        path: '/lupa-password',
        name: 'ForgetPassword',
        meta: {
            requiresAuth: 0,
            role: []
        },
        component: loadAuth('ForgetPassword')
    },
    {
        path: '/logout',
        name: 'Logout',
        meta: {
            requiresAuth: 1,
            role: []
        },
        component: loadAuth('Logout')
    },
]

export default routerFilter(routes)