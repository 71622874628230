import { loadSchool, loadTeacher } from '@/helpers/routes'

const routes = [
    {
        path: 'data-unit',
        name: 'UnitData',
        meta: {
            requiresAuth: 1,
            role: ['admin']
        },
        component: loadSchool('UnitData'),
    },
    {
        path: 'data-unit/:unit_id',
        name: 'UnitDataDetail',
        meta: {
            requiresAuth: 1,
            role: ['admin']
        },
        component: loadSchool('UnitDataDetail'),
    },
    {
        path: 'tahun-ajaran',
        name: 'SchoolYear',
        meta: {
            requiresAuth: 1,
            role: ['admin']
        },
        component: loadSchool('SchoolYear'),
    },
    {
        path: ':education_subject_type(mata-pelajaran|ekstrakurikuler)',
        name: 'EducationSubject',
        meta: {
            requiresAuth: 1,
            role: ['admin']
        },
        component: loadSchool('EducationSubject'),
    },
    {
        path: 'pembimbing-ekstrakurikuler',
        name: 'EducationSubjectAdvisor',
        meta: {
            requiresAuth: 1,
            role: ['admin', 'staff']
        },
        component: loadSchool('EducationSubjectAdvisor'),
    },
    {
        path: 'operator',
        name: 'Operator',
        meta: {
            requiresAuth: 1,
            role: ['admin']
        },
        component: loadSchool('Operator'),
    },
    {
        path: 'guru',
        name: 'Teacher',
        meta: {
            requiresAuth: 1,
            role: ['admin', 'staff']
        },
        component: loadSchool('Teacher'),
    },
    {
        path: 'guru/:user_id',
        name: 'TeacherDetail',
        meta: {
            requiresAuth: 1,
            role: ['admin', 'staff']
        },
        component: loadSchool('TeacherDetail'),
    },
    {
        path: 'kelas',
        name: 'ClassRoom',
        meta: {
            requiresAuth: 1,
            role: ['admin', 'staff']
        },
        component: loadSchool('ClassRoom'),
    },
    {
        path: 'kelas/:classroom_slot_id',
        name: 'ClassRoomDetail',
        meta: {
            requiresAuth: 1,
            role: ['admin', 'staff']
        },
        component: loadSchool('ClassRoomDetail'),
    },
    {
        path: 'kelas/:classroom_slot_id/presensi-mata-pelajaran/:classroom_subject_id',
        name: 'ClassRoomDetailSubject',
        meta: {
            requiresAuth: 1,
            role: ['admin', 'staff']
        },
        component: loadTeacher('ClassRoomDetail'),
    },
    {
        path: 'kelas/:classroom_slot_id/presensi-siswa/:classroom_student_id',
        name: 'ClassRoomDetailAttendance',
        meta: {
            requiresAuth: 1,
            role: ['admin', 'staff']
        },
        component: loadSchool('StudentDetail'),
    },
    {
        path: 'kelas/:classroom_slot_id/presensi-siswa/:classroom_student_id/:classroom_subject_id',
        name: 'ClassRoomDetailAttendanceList',
        meta: {
            requiresAuth: 1,
            role: ['admin', 'staff']
        },
        component: loadSchool('StudentDetail'),
    },
    {
        path: 'kelas/:classroom_slot_id/presensi-mata-pelajaran/:classroom_subject_id/:classroom_student_id',
        name: 'ClassRoomDetailAttendanceList2',
        meta: {
            requiresAuth: 1,
            role: ['admin', 'staff']
        },
        component: loadSchool('StudentDetail'),
    },
    {
        path: 'kelas/:classroom_slot_id/siswa/:user_id',
        name: 'ClassRoomDetailStudent',
        meta: {
            requiresAuth: 1,
            role: ['admin', 'staff']
        },
        component: loadSchool('ChildrenDetail'),
    },
    {
        path: 'orang-tua',
        name: 'Parent',
        meta: {
            requiresAuth: 1,
            role: ['admin', 'staff']
        },
        component: loadSchool('Parent'),
    },
    {
        path: 'orang-tua/:user_id',
        name: 'ParentDetail',
        meta: {
            requiresAuth: 1,
            role: ['admin', 'staff']
        },
        component: loadSchool('ParentDetail'),
    },
    {
        path: 'anak',
        name: 'Children',
        meta: {
            requiresAuth: 1,
            role: ['admin', 'staff']
        },
        component: loadSchool('Children'),
    },
    {
        path: 'anak/:user_id',
        name: 'ChildrenDetail',
        meta: {
            requiresAuth: 1,
            role: ['admin', 'staff']
        },
        component: loadSchool('ChildrenDetail'),
    },
    {
        path: 'siswa',
        name: 'Student',
        meta: {
            requiresAuth: 1,
            role: ['admin', 'staff']
        },
        component: loadSchool('Student'),
    },
    {
        path: 'siswa/:user_id',
        name: 'StudentDetail',
        meta: {
            requiresAuth: 1,
            role: ['admin', 'staff']
        },
        component: loadSchool('ChildrenDetail'),
    },
    {
        path: 'siswa/kelas/:classroom_student_id',
        name: 'ClassRoomStudentDetail',
        meta: {
            requiresAuth: 1,
            role: ['admin', 'staff']
        },
        component: loadSchool('StudentDetail'),
    },
    {
        path: 'kelas',
        name: 'ClassRoom',
        meta: {
            requiresAuth: 1,
            role: ['admin', 'staff']
        },
        component: loadSchool('ClassRoom'),
    },
    {
        path: 'jadwal-admisi',
        name: 'Admission',
        meta: {
            requiresAuth: 1,
            role: ['admin', 'staff']
        },
        component: loadSchool('Admission'),
    },
    {
        path: 'jadwal-admisi/:admission_schedule_id',
        name: 'AdmissionDetail',
        meta: {
            requiresAuth: 1,
            role: ['admin','staff']
        },
        component: loadSchool('AdmissionDetail'),
    },
    {
        path: 'jadwal-admisi/:admission_schedule_id/siswa/:user_id',
        name: 'AdmissionDetailStudent',
        meta: {
            requiresAuth: 1,
            role: ['admin','staff']
        },
        component: loadSchool('ChildrenDetail'),
    },
]

export default routes