import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import i18n from './i18n'
import '@/style/main.scss'
import 'material-icons/iconfont/material-icons.css';
import { VueReCaptcha } from 'vue-recaptcha-v3'
import VueExcelXlsx from "vue-excel-xlsx";

const runVue = async (r) => {
    const router = await r
    createApp(App)
        .use(VueReCaptcha, { siteKey: process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY })
        .use(i18n)
        .use(store)
        .use(router)
        .use(VueExcelXlsx)
        .mount('#app')
}
runVue(router)