
import { 
    postData, getData
} from '@/store/config/method'

const itemInit = {
    id: null,
    edu_level_id: null,
    file_id: null,
    judul: null,
    deskripsi: null,
    visi_misi: null,
}
const initialState = () => ({
    message: null,
    status: 0,
    data: {},
    item: {...itemInit},
})
const state = initialState
 
const getters = {
    getMessage: state => state.message,
    getStatus: state => state.status,
    getData: state => state.data || {},
    getItem: state => state.item,
}

const actions = {
    resetItem({commit}){
        commit('SET_ITEM',{...itemInit})
    },
    setItem({commit}, value){
        commit('SET_ITEM',{...value})
    },
    async submit({
        commit
    }, payload) {
        try {
            const response = await postData('api','/management/edu-level-detail',payload);
            commit('SET_RESPONSE', response.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    async fetchData({
        commit
    }, unit_type){
        try {
            const url = `/management/edu-level-detail/${unit_type.toUpperCase()}`
            const response = await getData('api',url);
            commit('SET_RESPONSE', response.data);
            commit('SET_DATA', response.data.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    resetState({commit}){
        commit('resetState')
    }
}

const mutations = {
    SET_FAILED(state) {
        state.message = 'Jaringan Bermasalah'
        state.status = 0
    },
    SET_FAILED_MESSAGE(state, error) {
        if(error.status == 400){
            state.message = error.data.message
            state.status = error.data.status
        }else{
            state.message = 'Jaringan Bermasalah'
            state.status = 0
        }
    },
    SET_RESPONSE(state, response) {
        state.message = response.message
        state.status = response.status
    },
    SET_DATA(state, value) {
        state.data = value
    },
  
    SET_ITEM(state, value){
        state.item = value
    },
    resetState (state) {
        const initial = initialState()
        Object.keys(initial).forEach(key => { state[key] = initial[key] })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}