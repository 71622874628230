import { mapGetters, mapActions } from 'vuex'
import InputCustom from '@/components/helper/Input'
import ButtonCustom from '@/components/helper/Button'
import AlertCustom from '@/components/helper/Alert'

export default {
    computed: {
        ...mapGetters({
            isMobile: 'getIsMobile',
            isLoggedIn: 'getisLoggedIn'
        }),
        currentUrl(){
            return `${window.location.protocol}//${window.location.hostname}`
        }
    },
    components: {
        InputCustom,
        ButtonCustom,
        AlertCustom
    },
    data: () => ({
        youtubeUrl: 'https://www.youtube.com/watch?v=',
        isLoading: false
    }),
    methods: {
        ...mapActions({
            showSnackbar: 'snackbar/showSnackbar',
        }),
        resetError(attr){
            this.error[attr] = false
        },
        getResponse(module){
            const status = this.$store.getters[`${module}/getStatus`]
            const message = this.$store.getters[`${module}/getMessage`]
            return {status, message}
        },
        formatPrice(number){
            return new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(number).split(',')[0]
        },    
    }
}