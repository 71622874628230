import client from './index'
import store from '@/store'

const getData = (type,url, request = {}) => client[type].get(url,request)
const postData = (type,url,data) => client[type].post(url,data)
const patchData = (type,url,data) => client[type].patch(url,data)
const putData = (type,url,data) => client[type].put(url,data)
const destroyData = (type,url) => client[type].delete(url)
const uploadData = (type,url,data) => client[type].post(url, data, {"Content-Type": "multipart/form-data",  onUploadProgress: progressEvent => {
    let percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
    store.dispatch('setUploadLoading',percentCompleted)
}})
const downloadFile = (type,url, params = {}) => client[type].get(url, { responseType: 'blob', params})

export {
    getData,
    postData,
    patchData,
    putData,
    destroyData,
    uploadData,
    downloadFile
}
