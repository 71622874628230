
import { 
    getData
} from '@/store/config/method'

const requestInit = {
    page: 1,
    show: 10,
    sortBy: null,
    sorting: null,
    search: null,
    edu_level: 'Semua Unit',
    edu_level_id: null,
    edu_level_slot: 'Pilih Tahun Ajaran',
    edu_level_slot_id: null,
    status_label: 'Semua Status',
    status: null
}

const attendancesRequestInit = {
    page: 1,
    show: 10,
    sortBy: null,
    sorting: null,
    search: null,
    tempDateBegin: new Date(),
    dateBegin: null,
    tempDateEnd: new Date(),
    dateEnd: null,
}
const detailAttendancesRequestInit = {
    status: null,
    status_label: 'Semua Status',
    date_start: null,
    date_end: null,
}

const initialState = () => ({
    message: null,
    status: 0,
    meta: {},
    data: [],
    request: {...requestInit},
    attendances: [],
    attendancesMeta: {},
    attendancesRequest: {...attendancesRequestInit},
    detailAttendances: [],
    detailAttendancesRequest: {...detailAttendancesRequestInit},
    detail: {},
    detailAttendancesTeacher: {}
})
const state = initialState
 
const getters = {
    getMessage: state => state.message,
    getStatus: state => state.status,
    getData: state => state.data || [],
    getMeta: state => state.meta || {},
    getRequest: state => state.request,
    getAttendances: state => state.attendances || [],
    getAttendancesMeta: state => state.attendancesMeta || {},
    getAttendancesRequest: state => state.attendancesRequest,
    getDetailAttendances: state => state.detailAttendances || [],
    getDetailAttendancesRequest: state => state.detailAttendancesRequest,
    getDetailAttendancesTeacher: state => state.detailAttendancesTeacher,
    getDetail: state => state.detail || {}
}

const actions = {
    resetRequest({commit}){
        commit('SET_REQUEST',{...requestInit})
    },
    resetAttendancesRequest({commit}){
        commit('SET_ATTENDANCESREQUEST',{...attendancesRequestInit})
    },
    resetDetailAttendancesRequest({commit}){
        commit('SET_DETAILATTENDANCESREQUEST',{...detailAttendancesRequestInit})
    },
    
    async fetchData({
        commit, state
    }){
        try {
            const response = await getData('api','/student-classroom',{params: state.request});
            commit('SET_RESPONSE', response.data);
            commit('SET_DATA', response.data.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    async showData({
        commit
    }, classroom_student_id){
        try {
            const url = `/student-classroom/${classroom_student_id}`
            const response = await getData('api',url);
            commit('SET_RESPONSE', response.data);
            commit('SET_DETAIL', response.data.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    async getAttendancesData({
        commit, state
    }, classroom_student_id){
        try {
            const url = `/student-classroom/attendances/${classroom_student_id}`
            const response = await getData('api',url,{params: state.attendancesRequest});
            commit('SET_RESPONSE', response.data);
            commit('SET_ATTENDANCES_DATA', response.data.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    async getDetailAttendancesData({
        commit, state
    }, {classroom_student_id, classroom_subject_id}){
        try {
            const url = `/management/user-student/attendance/${classroom_student_id}/${classroom_subject_id}`
            const response = await getData('api',url,{params: state.detailAttendancesRequest});
            commit('SET_RESPONSE', response.data);
            commit('SET_DETAIL_ATTENDANCES_DATA', response.data.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    resetState({commit}){
        commit('resetState')
    }
}

const mutations = {
    SET_FAILED(state) {
        state.message = 'Jaringan Bermasalah'
        state.status = 0
    },
    SET_FAILED_MESSAGE(state, error) {
        if(error.status == 400){
            state.message = error.data.message
            state.status = error.data.status
        }else{
            state.message = 'Jaringan Bermasalah'
            state.status = 0
        }
    },
    SET_RESPONSE(state, response) {
        state.message = response.message
        state.status = response.status
    },
    SET_DATA(state, response) {
        state.data = response.data
        state.meta = {
            current_page: response.current_page,
            from: response.from,
            last_page: response.last_page,
            per_page: response.per_page,
            to: response.to,
            total: response.total
        }
    },
    SET_LIST(state, value) {
        state.list = value
    },
    SET_REQUEST(state, value){
        state.request = value
    },
    SET_ATTENDANCESREQUEST(state, value){
        state.attendancesRequest = value
    },
    SET_DETAILATTENDANCESREQUEST(state, value){
        state.detailAttendancesRequest = value
    },
    SET_DETAIL(state, value){
        state.detail = value
    },
    SET_ATTENDANCES_DATA(state, response){
        state.attendances = response.data
        state.attendancesMeta = {
            current_page: response.current_page,
            from: response.from,
            last_page: response.last_page,
            per_page: response.per_page,
            to: response.to,
            total: response.total
        }
    },
    SET_DETAIL_ATTENDANCES_DATA(state, response){
        state.detailAttendances = response.data
        state.detailAttendancesTeacher = response.teacher
    },
    resetState (state) {
        const initial = initialState()
        Object.keys(initial).forEach(key => { state[key] = initial[key] })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}