import moment from 'moment'
import { Trans } from './translation'
 
const startDay = (month, year) => {
    return moment(`${year}-${month}`, 'YYYY-MM').startOf('month').day();
}
const endDate = (month,year) => {
    return moment(`${year}-${month}`, 'YYYY-MM').endOf('month').format('DD')
}
const timeFormat = (value) => {
    if (value) { return value.replace(/:[^:]*$/, '') }
}
const currentDate = () => {
    return moment().format('YYYY-MM-DD')
}
const dateFormat = (date) => {
    if(!date) return null
    return moment(date).format('YYYY-MM-DD')
}
const timeformat = (date) => {
    if(!date) return null
    return moment(date).format('HH:mm')
}
const addMonth = (month,year, add) => {
    return moment(`${year}-${month}`, 'YYYY-MM').add(add,'M').format('YYYY-MM')
}
const isPassedDate = (date, month, year) => {
    return moment(`${year}-${month}-${date}`, 'YYYY-MM-DD').format('YYYY-MM-DD') < currentDate()
}
const generateDate = (date,month,year) => {
    const selMonth = month || currentDate().split('-')[1]
    const selYear = year || currentDate().split('-')[0]
    return `${selYear}-${selMonth.toString().padStart(2,'0')}-${date.toString().padStart(2,'0')}`
}
const splitDate = (date) => { 
    const dateString = moment(date).format('YYYY-MM-DD')
    const dates = dateString.split('-')
    return {
        date: dates[2],
        month: dates[1],
        year: dates[0],
    }
}

const diffDateTime = (begin,end) =>{
    const dateBegin = moment(begin); 
    const dateEnd = moment(end);
    return dateEnd.diff(dateBegin, 'minutes')
}
const fullMonth = (value) => {
    moment.locale(Trans.currentLocale)
    return moment(value).format('DD MMMM YYYY')
}
const fullDateTime = (value) => {
    moment.locale(Trans.currentLocale)
    return moment(value).format('dddd, DD MMM YYYY - HH:mm')
}
const dateTime = (value) => {
    moment.locale(Trans.currentLocale)
    return moment(value).format('DD MMM YYYY HH:mm')
}

const diffDay = (begin,end) =>{
    if(begin == null || end == null) return -1;
    const dateBegin = moment(begin); 
    const dateEnd = moment(end);
    return dateEnd.diff(dateBegin, 'days')
}
const getLocaleMonths = (locale) => { 
    const months = moment.localeData(locale).months()
    return months.map((x,i) => { 
        return {
            id: parseInt(i) + 1,
            label: x
        }
    })
}

export {
    timeFormat,
    startDay,
    endDate,
    currentDate,
    addMonth,
    isPassedDate,
    generateDate,
    diffDateTime,
    fullMonth,
    fullDateTime,
    dateTime,
    diffDay,
    getLocaleMonths,
    splitDate,
    dateFormat,
    timeformat
}