
import { 
    postData, getData
} from '@/store/config/method'

const initialState = () => ({
    message: null,
    status: 0,
    data: [],
    count: 0
})
const state = initialState
 
const getters = {
    getMessage: state => state.message,
    getStatus: state => state.status,
    getData: state => state.data || [],
    getCount: state => state.count
}

const actions = {
    async store(_, payload) {
        try {
            const response = await postData('api','/management/notification/read',payload);
            return response.data
        } catch (err) {
            return responseError(err)
        }
    },
    async fetchData(){
        try {
            const response = await getData('api','/management/notification');
            return response.data
        } catch (err) {
            return responseError(err)
        }
    },
    async getCountData({commit}){
        try {
            const response = await getData('api','/management/notification/count');
            commit('SET_COUNT_DATA', response.data.data)
            return response.data
        } catch (err) {
            return responseError(err)
        }
    },
    resetState({commit}){
        commit('resetState')
    }
}

const responseError = (err) => { 
    let message = 'Jaringan Bermasalah'
    let status = 0
    if(err.response?.status == 400){
        message = err.response.data.message
        status = err.response.data.status
    }
    return {message, status, data: 0}
} 
const mutations = {
    SET_COUNT_DATA(state, value){
        state.count = value || 0
    },
    resetState (state) {
        const initial = initialState()
        Object.keys(initial).forEach(key => { state[key] = initial[key] })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}