import { 
    postData, getData
} from '@/store/config/method'

const itemInit = {
    "video-profil": null,
    "ikatan-alumni-masehi-kudus": null,
    "profil-yayasan": null,
    "telepon": null,
    "fax": null,
    "email": null,
}
const registrationItem = {
    id: null,
    registration_picture_id: null,
    full_path: null,
    filename: null,
    mime_type: null,
    file_size: null,
    registration_title: null,
    registration_subtitle: null,
    registration_is_active: false,
}
const initialState = () => ({
    message: null,
    status: 0,
    item: {...itemInit},
    data: {
        "video-profil": null,
        "ikatan-alumni-masehi-kudus": null,
        "profil-yayasan": null,
        "telepon": null,
        "fax": null,
        "email": null,
    },
    registrationItem: {...registrationItem}
})
const state = initialState
 
const getters = {
    getMessage: state => state.message,
    getStatus: state => state.status,
    getVideoProfil: state => state.data['video-profil'],
    getIkamaku: state => state.data['ikatan-alumni-masehi-kudus'],
    getFoundationProfile: state => state.data['profil-yayasan'],
    getPhone: state => state.data['telepon'],
    getFax: state => state.data['fax'],
    getEmail: state => state.data['email'],
    getItem: state => (key) => state.item[key],
    getRegistrationItem: state => state.registrationItem
}

const actions = {
    setItem({commit}, {value,key}){
        commit('SET_ITEM', {value,key})
    },
    setData({commit}, {value,key}){
        commit('SET_DATA', {value,key})
    },
    async submit({
        commit
    }, payload) {
        try {
            const response = await postData('api','/management/setting',payload);
            commit('SET_RESPONSE', response.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    async addRegistrationSetting({
        commit
    }, payload) {
        try {
            const response = await postData('api','/management/setting/home-registration',payload);
            commit('SET_RESPONSE', response.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    async fetchData({
        commit
    },key){
        try {
            const url = `/management/setting/key/${key}`
            const response = await getData('api',url);
            commit('SET_RESPONSE', response.data);
            commit('SET_DATA', {value: response.data.data?.value || null, key});
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    async getRegistrationSettingData({
        commit
    }){
        try {
            const url = `/management/setting/home-registration`
            const response = await getData('api',url);
            commit('SET_RESPONSE', response.data);
            commit('SET_REGISTRATION_SETTING_DATA', response.data.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    resetState({commit}){
        commit('resetState')
    }
}

const mutations = {
    SET_FAILED(state) {
        state.message = 'Jaringan Bermasalah'
        state.status = 0
    },
    SET_FAILED_MESSAGE(state, error) {
        if(error.status == 400){
            state.message = error.data.message
            state.status = error.data.status
        }else{
            state.message = 'Jaringan Bermasalah'
            state.status = 0
        }
    },
    SET_RESPONSE(state, response) {
        state.message = response.message
        state.status = response.status
    },
    SET_DATA(state, {value, key}) {
        state.data[key] = value
    },
    SET_ITEM(state, {value, key}) {
        state.item[key] = value
    },
    SET_REGISTRATION_SETTING_DATA(state, value) {
        state.registrationItem = {
            ...state.registrationItem,
            ...value
        }
    },
    resetState (state) {
        const initial = initialState()
        Object.keys(initial).forEach(key => { state[key] = initial[key] })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}