import { loadWebsite, loadSchool } from '@/helpers/routes'

const routes = [
    {
        path: 'kritik-dan-saran',
        name: 'Suggestion',
        meta: {
            requiresAuth: 1,
            role: ['admin', 'staff']
        },
        component: loadWebsite('Suggestion'),
    },
    {
        path: 'kritik-dan-saran/:user_id',
        name: 'SuggestionParentDetail',
        meta: {
            requiresAuth: 1,
            role: ['admin', 'staff']
        },
        component: loadSchool('ParentDetail'),
    },
    {
        path: 'faq',
        name: 'Faq',
        meta: {
            requiresAuth: 1,
            role: ['admin']
        },
        component: loadWebsite('Faq'),
    },
    {
        path: 'kategori-blog',
        name: 'BlogCategory',
        meta: {
            requiresAuth: 1,
            role: ['admin','staff']
        },
        component: loadWebsite('BlogCategory'),
    },
    {
        path: ':article_type(prestasi|blog|pengumuman)',
        name: 'Article',
        meta: {
            type: ':article_type',
            requiresAuth: 1,
            role: ['admin', 'staff']
        },
        component: loadWebsite('Article'),
    },
    {
        path: 'manajemen-website',
        name: 'WebManagement',
        meta: {
            requiresAuth: 1,
            role: ['admin']
        },
        component: loadWebsite('WebManagement'),
    },
    {
        path: 'manajemen-website/banner',
        name: 'Banner',
        meta: {
            requiresAuth: 1,
            role: ['admin']
        },
        component: loadWebsite('Banner'),
    },
    {
        path: 'manajemen-website/halaman-utama',
        name: 'MainPage',
        meta: {
            requiresAuth: 1,
            role: ['admin']
        },
        component: loadWebsite('MainPage'),
    },
    {
        path: 'manajemen-website/struktur-organisasi',
        name: 'OrganizationStructureLayout',
        meta: {
            requiresAuth: 1,
            role: ['admin']
        },
        component: loadWebsite('OrganizationStructureLayout'),
    },
    {
        path: 'manajemen-website/unit-pendidikan',
        name: 'UnitDataHome',
        meta: {
            requiresAuth: 1,
            role: ['admin']
        },
        component: loadWebsite('UnitDataHome'),
    },
    {
        path: 'manajemen-website/unit-pendidikan/:slug',
        name: 'UnitDetail',
        meta: {
            requiresAuth: 1,
            role: ['admin']
        },
        component: loadWebsite('UnitDetail'),
    },
    {
        path: 'manajemen-website/profil-yayasan',
        name: 'FoundationProfile',
        meta: {
            requiresAuth: 1,
            role: ['admin']
        },
        component: loadWebsite('FoundationProfile'),
    },
    {
        path: 'manajemen-website/ikamaku',
        name: 'Ikamaku',
        meta: {
            requiresAuth: 1,
            role: ['admin']
        },
        component: loadWebsite('Ikamaku'),
    },
    {
        path: 'manajemen-website/kontak',
        name: 'Contact',
        meta: {
            requiresAuth: 1,
            role: ['admin']
        },
        component: loadWebsite('Contact'),
    },
]

export default routes