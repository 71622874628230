
import { 
    postData, getData, destroyData
} from '@/store/config/method'

const requestInit = {
    page: 1,
    show: 10,
    sortBy: null,
    sorting: null,
    search: null,
    edu_level: 'Semua Unit',
    edu_level_id: null,
    status_label: 'Semua Status',
    status: null
}

const parentDetail = {
    income_id: null,
    income: null,
    job_id: null,
    job: null,
    education: null,
    dob: null,
    profile_picture: null,
    address: null,
    phone_number: null,
    nik: null,
    name: null,
    image: null,
}

const itemInit = {
    id: null,
    name: null,
    email: null,
    status: false,
    user_detail_id: null,
    religion_id: null,
    religion_name: null,
    location_id: null,
    location_name: null,
    address: null,
    profile_picture: null,
    phone_number: null,
    dob: null,
    father: {...parentDetail},
    mother: {...parentDetail},
    wali: {...parentDetail},
}
const initialState = () => ({
    message: null,
    status: 0,
    meta: {},
    data: [],
    request: {...requestInit},
    item: {...itemInit},
    list: [],
    parent: {
        father: {...parentDetail},
        mother: {...parentDetail},
        wali: {...parentDetail},
    }
})
const state = initialState
 
const getters = {
    getMessage: state => state.message,
    getStatus: state => state.status,
    getData: state => state.data || [],
    getMeta: state => state.meta || {},
    getRequest: state => state.request,
    getItem: state => state.item,
    getList: state => state.list,
    getParent: state => state.parent
}

const actions = {
    resetRequest({commit}){
        commit('SET_REQUEST',{...requestInit})
    },
    resetItem({commit}){
        commit('SET_ITEM',{...itemInit})
    },
    setItem({commit}, value){
        commit('SET_ITEM',{...value})
    },
    async store({
        commit
    }, data) {
        try {
            const payload = {...data}
            delete payload.id
            const response = await postData('api','/management/parent',payload);
            commit('SET_RESPONSE', response.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    async fetchData({
        commit, state
    }){
        try {
            const response = await getData('api','/management/parent',{params: state.request});
            commit('SET_RESPONSE', response.data);
            commit('SET_DATA', response.data.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    async getDataByGroupId({
        commit
    }, id){
        try {
            const url = `/management/search/parent-by-group-id/${id}`
            const response = await getData('api',url);
            commit('SET_RESPONSE', response.data);
            commit('SET_PARENT_DATA', response.data.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    async getDetail({
        commit
    }, user_id){
        try {
            const url = `/management/parent/${user_id}`
            const response = await getData('api',url);
            commit('SET_RESPONSE', response.data);
            const item = response.data.data
            const payload = {
                ...item,
                image: null,
                father: {...item.father, image: null} || {image: null},
                mother: {...item.mother, image: null} || {image: null},
                wali: {...item.wali, image: null} || {image: null}
            }
            commit('SET_ITEM', payload);
            const parentData = {
                parent_group_id: item.parent_group_id,
                father_name: item.father?.name || null,
                mother_name: item.mother?.name || null,
                wali_name: item.wali?.name || null,
                father_picture: item.father?.profile_picture || null,
            }
            commit('parentChildren/SET_FATHER_DATA', parentData, { root: true })
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    async update({
        commit
    }, data) {
        try {
            const url = `/management/parent/${data.id}`
            const response = await postData('api',url,data);
            commit('SET_RESPONSE', response.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    async destroy({
        commit
    }, data) {
        try {
            const url = `/management/parent/${data.id}`
            const response = await destroyData('api',url);
            commit('SET_RESPONSE', response.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    resetState({commit}){
        commit('resetState')
    }
}

const mutations = {
    SET_FAILED(state) {
        state.message = 'Jaringan Bermasalah'
        state.status = 0
    },
    SET_FAILED_MESSAGE(state, error) {
        if(error.status == 400){
            state.message = error.data.message
            state.status = error.data.status
        }else{
            state.message = 'Jaringan Bermasalah'
            state.status = 0
        }
    },
    SET_RESPONSE(state, response) {
        state.message = response.message
        state.status = response.status
    },
    SET_DATA(state, response) {
        state.data = response.data
        state.meta = {
            current_page: response.current_page,
            from: response.from,
            last_page: response.last_page,
            per_page: response.per_page,
            to: response.to,
            total: response.total
        }
    },
    SET_LIST(state, value) {
        state.list = value
    },
    SET_REQUEST(state, value){
        state.request = value
    },
    SET_ITEM(state, value){
        state.item = value
    },
    SET_PARENT_DATA(state, item){
        state.parent = {
            father: {...item.father, image: null} || {image: null},
            mother: {...item.mother, image: null} || {image: null},
            wali: {...item.wali, image: null} || {image: null}
        }
    },
    resetState (state) {
        const initial = initialState()
        Object.keys(initial).forEach(key => { state[key] = initial[key] })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}