<template>
    <div :class="`r-alert r-alert-${type}`">
        <div :class="withImage ? 'r-center-flex' : 'r-center-text'">
            <img :src="require(`@/assets/icons/toast/${type}.svg`)" class="r-mr-4" :alt="type" v-if="withImage">
            <span>
                <slot></slot>
            </span>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        type: {
            default: 'info'
        },
        withImage: {
            default: true
        }
    }
}
</script>