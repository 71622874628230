import { loadDashboard } from '@/helpers/routes'

const routes = [
    {
        path: 'file-management',
        name: 'FileLayout',
        meta: {
            requiresAuth: 1,
            role: ['admin','staff']
        },
        component: loadDashboard('FileLayout'),
    },
    {
        path: 'file-management/:file_id',
        name: 'FileManagementDetail',
        meta: {
            requiresAuth: 1,
            role: ['admin', 'staff']
        },
        component: loadDashboard('FileManagementDetail'),
    },
    {
        path: 'pengaturan-akun',
        name: 'AccountSetting',
        meta: {
            requiresAuth: 1,
            role: ['admin', 'staff', 'teacher']
        },
        component: loadDashboard('AccountSetting'),
    },
    
]

export default routes