import modules from './modules'
import VuexPersistence from 'vuex-persist';
import localForage from 'localforage';
import { createStore } from 'vuex'
import { getLocaleMonths } from '@/helpers/timeformat'

localForage.config({
  name        : 'msm-fe-website-cms',
  version     : 1.0,
  storeName   : 'ybpm', // Should be alphanumeric, with underscores.
});

const masterStorage1 = new VuexPersistence({
  key: 'master-data',
  storage: localForage,
  reducer: state => ({ locale: state.locale, dateMaster: state.dateMaster }),
  asyncStorage: true
})
const authStorage = new VuexPersistence({
  key: 'auth',
  storage: localForage,
  reducer: state => ({ auth: state.auth, createdAt: state.createdAt }),
  asyncStorage: true
})

export default createStore({
  state: {
    isLoggedIn: !!localStorage.getItem('schkey'),
    createdAt: null,
    metaData: {
        title: '',
        description: '',
        keywords: ''
    },
    isMobile: false,
    uploadLoading: 0,
    dateMaster: {
      dateList: [],
      monthList: [],
      yearList: [],
      ageLimit: 0,
      locale: null
    }
  },
  getters: {
    getMetaData: state => state.metaData,
    getisLoggedIn: state => state.isLoggedIn,
    getIsMobile: state => state.isMobile,
    getUploadLoading: state => state.uploadLoading,
    getDateList: state => state.dateMaster.dateList,
    getMonthList: state => state.dateMaster.monthList,
    getYearList: state => state.dateMaster.yearList,
    getCreatedAt: state => state.createdAt,
  },
  actions: {
    setCreatedAt({commit}, value){
      commit('SET_CREATED_AT',value)
    },
    setDateList({commit, state}){
      if(state.dateMaster.dateList.length == 0){
        const date = [];
        for (let i = 1; i < 32; i++) {
            date.push(i);
        }
        commit('SET_DATE_LIST',date)
      }
    },
    setMonthList({commit, state}, locale){
      if(state.dateMaster.locale != locale){
        const months = getLocaleMonths(locale)
        commit('SET_MONTH_LIST', {months, locale})
      }
    },
    setYearList({commit, state}, {from, to}){
      if(state.dateMaster.ageLimit != to){
        const year = [];
        const today = new Date();
        const thisYear = today.getFullYear();
        for (let i = from; i < to; i++) {
            year.push(thisYear - i);
        }
        commit('SET_YEAR_LIST', {year, to})
      }
    },
    setYearList2({commit}, year){
      commit('SET_YEAR_LIST', {year, to: null})
    },
    setUploadLoading({commit}, value){
      commit('SET_UPLOAD_LOADING', value)
    },
    setIsMobile({commit}, value){
      const result = value < 800
      commit('SET_IS_MOBILE', result)
    },
    setIsLoggedIn({commit}, value){
      commit("SET_IS_LOGGED_IN", value)
    },
    setMetaTag({commit}, {
        title = null, 
        description = null, 
        keywords = null
      }){
        commit("SET_META_TAG", {title, description, keywords})
    },
    async logoutStorage({commit,state}){
      localStorage.clear();
      state.isLoggedIn = false
      const initial = modules
      Object.keys(initial).forEach(key =>  commit(`${key}/resetState`))
    }
  },
  mutations: {
    SET_DATE_LIST(state, value){
      state.dateMaster.dateList = value
    },
    SET_MONTH_LIST(state, data){
      state.dateMaster.monthList = data.months
      state.dateMaster.locale = data.locale
    },
    SET_YEAR_LIST(state, data){
      state.dateMaster.yearList = data.year
      state.dateMaster.ageLimit = data.limit
    },
    SET_UPLOAD_LOADING(state, value) {
      state.uploadLoading = value;
    },
    SET_IS_LOGGED_IN(state, value) {
      state.isLoggedIn = value;
    },
    SET_IS_MOBILE(state, value){
      state.isMobile = value
    },
    SET_CREATED_AT(state, value){
      state.createdAt = value
    },
    SET_META_TAG(state, {title, description, keywords}){
      if(title) state.metaData.title = title 

      if(description) state.metaData.description = description

      if(keywords) state.metaData.keywords = keywords
    }
  },
  modules,
  plugins: [masterStorage1.plugin, authStorage.plugin]

})