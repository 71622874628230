
import { 
    postData, getData, destroyData, downloadFile
} from '@/store/config/method'

const requestInit = {
    page: 1,
    show: 10,
    sortBy: 'subject',
    sorting: null,
    search: null,
    edu_level: 'Semua Unit',
    edu_level_id: null,
    status_label: 'Semua Status',
    status: null,
    tempDateBegin: new Date(),
    dateBegin: null,
    tempDateEnd: new Date(),
    dateEnd: null,
}

const itemInit = {
    id: null,
    edu_level_slot_id: null,
    edu_level_slot: null,
    edu_level_class_id: null,
    edu_level_class: null,
    edu_level_id: null,
    edu_level: null,
    classroom_id: null,
    classroom: null,
    is_new_student: 0,
    search: null
}
const initialState = () => ({
    message: null,
    status: 0,
    meta: {},
    data: [],
    dataExport: [],
    dataColumns: [],
    attendances: [],
    request: {...requestInit},
    item: {...itemInit},
    list: [],
    importStudentsData: [],
    importStudentsMeta: {}
})
const state = initialState
 
const getters = {
    getMessage: state => state.message,
    getStatus: state => state.status,
    getData: state => state.data || [],
    getDataExport: state => state.dataExport || [],
    getColumnsExport: state => state.dataColumns || [],
    getMeta: state => state.meta || {},
    getRequest: state => state.request,
    getItem: state => state.item,
    getList: state => state.list,
    getImportStudentsData: state => state.importStudentsData || [],
    getImportStudentsMeta: state => state.importStudentsMeta || {},
    getAttendances: state => state.attendances || []
}

const actions = {
    resetRequest({commit}){
        commit('SET_REQUEST',{...requestInit})
    },
    resetItem({commit}, detail){
        const payload = {
            ...itemInit,
            edu_level_id: detail.edu_level_id,
            edu_level: detail.edu_level
        }
        commit('SET_ITEM',{...payload})
    },
    setItem({commit}, value){
        commit('SET_ITEM',{...value})
    },
    async submit({
        commit
    }, data) {
        try {
            const url = `/management/classroom-student/${data.classroom_slot_id}`
            const response = await postData('api',url,data);
            commit('SET_RESPONSE', response.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    async destroy({
        commit
    }, data) {
        try {
            const url = `/management/classroom-student/${data.id}`
            const response = await destroyData('api',url);
            commit('SET_RESPONSE', response.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    async listImportStudent({
        commit, state
    }, classroom_slot_id) {
        try {
            const url = `/management/list-import-student/${classroom_slot_id}`
            const response = await getData('api',url,{params: state.item});
            commit('SET_RESPONSE', response.data);
            commit('SET_IMPORT_STUDENTS_DATA', response.data.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    async fetchData({
        commit, state
    }, classroom_slot_id){
        try {
            const url = `/management/classroom-student/${classroom_slot_id}`
            const response = await getData('api',url,{params: state.request});
            commit('SET_RESPONSE', response.data);
            commit('SET_DATA', response.data.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    async getAttendancesData({
        commit
    }, {classroom_slot_id, year, month}){
        try {
            const params = {
                year, month
            }
            const url = `/management/classroom-student/attendances/${classroom_slot_id}`
            const response = await getData('api',url,{params});
            
            commit('SET_RESPONSE', response.data);
            commit('SET_ATTENDANCES_DATA', response.data.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    async downloadAttendancesData(_, {classroom_slot_id, year, month}) 
    {
        try {
            const params = {
                year, month
            }
            const url = `/management/classroom-student/attendances/download/${classroom_slot_id}`
            const response = await downloadFile('api',url,params);
            return {blob: new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }), status: 1}
        } catch (err) {
            let message = 'Jaringan Bermasalah'
            let status = 0
            if(err.response?.status == 400){
                message = err.response.data.message
                status = err.response.data.status
            }
            return {message, status}
        }
    },
    async downloadClassroomStudentData({
        commit, state
    }, classroom_slot_id)
    {
        try {
          const url = `/management/classroom-student/download/${classroom_slot_id}`;
          const response = await getData("api", url, { params: state.request });
          commit("SET_RESPONSE", response.data);
          commit("SET_DATA_EXPORT", response.data);
        } catch (err) {
          if (err.response) {
            commit("SET_FAILED_MESSAGE", err.response);
          } else {
            commit("SET_FAILED");
          }
        }
    },
    async downloadClassroomStudent(_, {classroom_slot_id}) 
    {
        try {
            const url = `/management/classroom-student/download-new/${classroom_slot_id}`;
            const response = await downloadFile('api',url);
            return {blob: new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }), status: 1}
        } catch (err) {
            let message = 'Jaringan Bermasalah'
            let status = 0
            if(err.response?.status == 400){
                message = err.response.data.message
                status = err.response.data.status
            }
            return {message, status}
        }
    },

    async getMyStudent({
        commit, state
    }, classroom_subject_id){
        try {
            const url = `/management/classroom-student/teacher/${classroom_subject_id}`
            const response = await getData('api',url,{params: state.request});
            commit('SET_RESPONSE', response.data);
            commit('SET_DATA', response.data.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    resetState({commit}){
        commit('resetState')
    }
}

const mutations = {
    SET_FAILED(state) {
        state.message = 'Jaringan Bermasalah'
        state.status = 0
    },
    SET_FAILED_MESSAGE(state, error) {
        if(error.status == 400){
            state.message = error.data.message
            state.status = error.data.status
        }else{
            state.message = 'Jaringan Bermasalah'
            state.status = 0
        }
    },
    SET_RESPONSE(state, response) {
        state.message = response.message
        state.status = response.status
    },
    SET_DATA(state, response) {
        state.data = response.data
        state.meta = {
            current_page: response.current_page,
            from: response.from,
            last_page: response.last_page,
            per_page: response.per_page,
            to: response.to,
            total: response.total
        }
    },
    SET_DATA_EXPORT(state, response) {
        state.dataExport = response.data.students
        state.dataColumns = response.data.columns
    },
    SET_ATTENDANCES_DATA(state, value) {
        state.attendances = value
    },
    SET_LIST(state, value) {
        state.list = value
    },
    SET_IMPORT_STUDENTS_DATA(state, response) {
        state.importStudentsData = response.data
        state.importStudentsMeta = {
            current_page: response.current_page,
            from: response.from,
            last_page: response.last_page,
            per_page: response.per_page,
            to: response.to,
            total: response.total
        }
    },
    SET_REQUEST(state, value){
        state.request = value
    },
    SET_ITEM(state, value){
        state.item = value
    },
    resetState (state) {
        const initial = initialState()
        Object.keys(initial).forEach(key => { state[key] = initial[key] })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}